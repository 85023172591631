import React, { useEffect, useState, useContext } from "react";
import "./style.scss";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import {
  getRepiquageByMag,
  saveRepiquage,
  emailRepiquage,
} from "../../../../services/engagement_apis";
import useAuth from "../../../../hooks/useAuth";
import { YearContext } from "../../../../contexts/YearContext";
import Valider from "./Statut/valider";
import Modifier from "./Statut/modifier";
import Attente from "./Statut/attente";

const Repiquage = () => {
  const { magasin } = useAuth();
  const [repiquageData, setRepiquageData] = useState({});
  const { selectedYear, setSelectedYear } = useContext(YearContext);

  useEffect(() => {
    const loadData = async () => {
      try {
        const repiquageResponse = await getRepiquageByMag(magasin.mag_code);

        setRepiquageData(repiquageResponse);
      } catch (error) {
        console.error("Could not fetch data from API", error);
      }
    };
    loadData();
  }, [magasin.mag_code, selectedYear]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleSubmit = async (updatedData) => {
    try {
      await saveRepiquage(updatedData);
      const repiquageResponse = await getRepiquageByMag(magasin.mag_code);
      await emailRepiquage(updatedData);

      await emailRepiquage({
        mag_code: "27",
        annee: 2025,
      });
      setRepiquageData(repiquageResponse);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données", error);
    }
  };

  const currentYearData =
    repiquageData[selectedYear] && repiquageData[selectedYear][0];

  return (
    <>
      <div className="CmNationale-titre">LES REPIQUAGES</div>
      <ReturnBtn />
      <div className="year-selector">
        <label htmlFor="year">Année : </label>
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          {Object.keys(repiquageData).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="repiquage-container">
        {/* Nom du magasin et ville */}
        <div className="magasin-info">
          <h1>
            {magasin.mag_nom} / {magasin.mag_ville}
          </h1>
        </div>
        {currentYearData ? (
          <>
            {currentYearData.id_statut === 0 && (
              <Attente
                data={currentYearData}
                year={selectedYear}
                onSubmit={handleSubmit}
              />
            )}
            {currentYearData.id_statut === 1 && (
              <Valider data={currentYearData} year={selectedYear} />
            )}
            {currentYearData.id_statut === 2 && <Modifier />}
          </>
        ) : (
          <div>Aucun commentaire pour cette année.</div>
        )}
      </div>
    </>
  );
};

export default Repiquage;
