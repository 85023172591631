export const DOMAIN = {
  //========== CONFIG DEV ==========//
  // URL: "https://origine.inedis.fr/",
  // URL_API: "http://192.168.111.118/api/",
  // URL_WEB: "http://192.168.111.118/",
  // URL_APP: "http://192.168.111.118:3000/",

  // ========== CONFIG PROD ==========//
  URL: "https://origine.inedis.fr/",
  URL_API: "https://api.inedis.fr/api/",
  URL_WEB: "https://api.inedis.fr/",
  URL_APP: "https://extranet.inedis.fr/",
};

export const STATUS = {
  MAINTENANCE: false,
};
