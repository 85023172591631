import React, { useContext, useState, useEffect } from "react";
import { OperationContext } from "../operationContext";
import { formatDate } from "../../../../../utils/dates";
import GeneralSkeleton from "./skeleton/generalSkeleton";

const General = () => {
  const {
    operationsData,
    magOpeData,
    formState,
    handleInputChange,
    pagiOptions,
    isLoading,
    dateErrorMessages,
  } = useContext(OperationContext);

  const [errorMessage, setErrorMessage] = useState("");

  // Validation check on option selection
  useEffect(() => {
    if (!formState.id_ope_pagi_option) {
      setErrorMessage("Veuillez sélectionner une option.");
    } else {
      setErrorMessage("");
    }
  }, [formState.id_ope_pagi_option]);

  if (isLoading) {
    return <GeneralSkeleton />;
  }

  return (
    <>
      {operationsData.id_ope_type === 1 && (
        <div className="block medium title-operation">
          <h3>{operationsData.ope_libelle}</h3>
          <div className="selection-container">
            <div className="left-selection">
              <div className="form-group">
                <label htmlFor="pagi-select">Sélectionnez une Pagination</label>
                <select
                  id="pagi-select"
                  className="styled-select"
                  name="id_ope_pagi"
                  value={formState.id_ope_pagi || ""}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Choisir une Pagination
                  </option>
                  {operationsData &&
                    operationsData.pagi.map((pagi) => (
                      <option key={pagi.id_ope_pagi} value={pagi.id_ope_pagi}>
                        {pagi.depliant_pagi.pagi_libelle}
                      </option>
                    ))}
                </select>
                {pagiOptions && pagiOptions.length > 0 && (
                  <>
                    <label htmlFor="option-select">
                      Sélectionnez une Option
                    </label>
                    <select
                      id="option-select"
                      className="styled-select"
                      name="id_ope_pagi_option"
                      value={formState.id_ope_pagi_option || ""}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Choisir une Option
                      </option>
                      {pagiOptions.map((option) => (
                        <option
                          key={option.id_ope_pagi_option}
                          value={option.id_ope_pagi_option}
                        >
                          {option.ope_libelle}
                        </option>
                      ))}
                    </select>
                    {errorMessage && (
                      <span className="error-message">{errorMessage}</span>
                    )}
                  </>
                )}
                <label htmlFor="type-select">Sélectionnez un format</label>
                <select
                  id="type-select"
                  className="styled-select"
                  name="mo_digital"
                  value={formState.mo_digital}
                  onChange={handleInputChange}
                >
                  <option value="0">Dépliant papier</option>
                  <option value="1">Dépliant digital</option>
                </select>
              </div>
            </div>
            <div className="right-selection">
              <div className="form-group">
                <label>Sélectionnez une Date</label>
                {operationsData.ope_datedeb1 && operationsData.ope_datefin1 && (
                  <label>
                    <input
                      type="radio"
                      name="mo_choix_date"
                      value="1"
                      checked={formState.mo_choix_date === "1"}
                      onChange={handleInputChange}
                    />{" "}
                    Du {formatDate(operationsData.ope_datedeb1)} au{" "}
                    {formatDate(operationsData.ope_datefin1)}
                  </label>
                )}
                {operationsData.ope_datedeb2 && operationsData.ope_datefin2 && (
                  <label>
                    <input
                      type="radio"
                      name="mo_choix_date"
                      value="2"
                      checked={formState.mo_choix_date === "2"}
                      onChange={handleInputChange}
                    />{" "}
                    Du {formatDate(operationsData.ope_datedeb2)} au{" "}
                    {formatDate(operationsData.ope_datefin2)}
                  </label>
                )}

                {operationsData.ope_dateautre === 1 && (
                  <label>
                    <input
                      type="radio"
                      name="mo_choix_date"
                      value="3"
                      checked={formState.mo_choix_date === "3"}
                      onChange={handleInputChange}
                    />{" "}
                    Autre Date
                  </label>
                )}
                {formState.mo_choix_date === "3" && (
                  <div className="other-date-inputs">
                    <label>
                      Date de début:
                      <input
                        type="date"
                        name="mo_datedeb3"
                        value={formState.mo_datedeb3}
                        onChange={handleInputChange}
                      />
                      {dateErrorMessages.mo_datedeb3 && (
                        <span className="error-message">
                          {dateErrorMessages.mo_datedeb3}
                        </span>
                      )}
                    </label>
                    <label>
                      Date de fin:
                      <input
                        type="date"
                        name="mo_datefin3"
                        value={formState.mo_datefin3}
                        onChange={handleInputChange}
                      />
                      {dateErrorMessages.mo_datefin3 && (
                        <span className="error-message">
                          {dateErrorMessages.mo_datefin3}
                        </span>
                      )}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="footer-section">
            <label htmlFor="specific-cover">
              Couverture spécifique {operationsData.couv_spe.param_valeur_num} €
            </label>
            <textarea
              id="specific-cover"
              name="mo_txt_couv_spe"
              value={formState.mo_txt_couv_spe}
              onChange={handleInputChange}
              placeholder="Entrez le texte ici..."
            />
          </div>
        </div>
      )}
      {operationsData.id_ope_type === 3 && (
        <div className="block small title-operation">
          <h3>{operationsData.ope_libelle}</h3>
          <div className="selection-container">
            <div className="form-group">
              <label>{operationsData.ope_description}</label>
              <label>Sélectionnez une Date</label>
              {/* Vérifier si datedeb1 et datefin1 existent avant d'afficher */}
              {operationsData.ope_datedeb1 && operationsData.ope_datefin1 && (
                <label>
                  <input
                    type="radio"
                    name="mo_choix_date"
                    value="1"
                    checked={formState.mo_choix_date === "1"}
                    onChange={handleInputChange}
                  />
                  Du {formatDate(operationsData.ope_datedeb1)} au{" "}
                  {formatDate(operationsData.ope_datefin1)}
                </label>
              )}

              {/* Vérifier si datedeb2 et datefin2 existent avant d'afficher */}
              {operationsData.ope_datedeb2 && operationsData.ope_datefin2 && (
                <label>
                  <input
                    type="radio"
                    name="mo_choix_date"
                    value="2"
                    checked={formState.mo_choix_date === "2"}
                    onChange={handleInputChange}
                  />
                  Du {formatDate(operationsData.ope_datedeb2)} au{" "}
                  {formatDate(operationsData.ope_datefin2)}
                </label>
              )}

              {operationsData.ope_dateautre === 1 && (
                <label>
                  <input
                    type="radio"
                    name="mo_choix_date"
                    value="3"
                    checked={formState.mo_choix_date === "3"}
                    onChange={handleInputChange}
                  />
                  Autre Date
                </label>
              )}
              {formState.mo_choix_date === "3" && (
                <div className="other-date-inputs">
                  <label>
                    Date de début:
                    <input
                      type="date"
                      name="mo_datedeb3"
                      value={formState.mo_datedeb3}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>
                    Date de fin:
                    <input
                      type="date"
                      name="mo_datefin3"
                      value={formState.mo_datefin3}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {operationsData.id_ope_type === 5 && (
        <div className="block medium title-operation">
          <h3>{operationsData.ope_libelle}</h3>
          <div className="selection-container">
            <div className="left-selection">
              <div className="form-group">
                <label>Sélectionnez une Date</label>

                {/* Vérifier si datedeb1 et datefin1 existent avant d'afficher */}
                {operationsData.ope_datedeb1 && operationsData.ope_datefin1 && (
                  <label>
                    <input
                      type="radio"
                      name="mo_choix_date"
                      value="1"
                      checked={formState.mo_choix_date === "1"}
                      onChange={handleInputChange}
                    />
                    Du {formatDate(operationsData.ope_datedeb1)} au{" "}
                    {formatDate(operationsData.ope_datefin1)}
                  </label>
                )}

                {/* Vérifier si datedeb2 et datefin2 existent avant d'afficher */}
                {operationsData.ope_datedeb2 && operationsData.ope_datefin2 && (
                  <label>
                    <input
                      type="radio"
                      name="mo_choix_date"
                      value="2"
                      checked={formState.mo_choix_date === "2"}
                      onChange={handleInputChange}
                    />
                    Du {formatDate(operationsData.ope_datedeb2)} au{" "}
                    {formatDate(operationsData.ope_datefin2)}
                  </label>
                )}

                {operationsData.ope_dateautre === 1 && (
                  <label>
                    <input
                      type="radio"
                      name="mo_choix_date"
                      value="3"
                      checked={formState.mo_choix_date === "3"}
                      onChange={handleInputChange}
                    />
                    Autre Date
                  </label>
                )}
                {formState.mo_choix_date === "3" && (
                  <div className="other-date-inputs">
                    <label>
                      Date de début:
                      <input
                        type="date"
                        name="mo_datedeb3"
                        value={formState.mo_datedeb3}
                        onChange={handleInputChange}
                      />
                    </label>
                    <label>
                      Date de fin:
                      <input
                        type="date"
                        name="mo_datefin3"
                        value={formState.mo_datefin3}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="right-selection">
              <div className="form-group">
                <label>{operationsData.ope_description}</label>
                <label htmlFor="qteSms">Quantité</label>
                <input
                  className="input-qte"
                  id="qteSms"
                  name="mo_courrier_qte"
                  type="number"
                  value={formState.mo_courrier_qte}
                  onChange={handleInputChange}
                />
                <label>
                  Quantité de boîtes aux lettres sur la platefome Adelya mise à
                  jour le {formatDate(magOpeData.potentiel.mp_date_maj)}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default General;
