import React from "react";
import "./style.scss";
import useModal from "../../../../../hooks/useModal";
import useNotification from "../../../../../hooks/useNotification";
import { formatDate, formatPrix } from "../../../../../utils/dates";
import BasicNotification from "../../../notification/BasicNotification";
import ValidationMailModal from "../../ValidationMailModal";
import Button from "../../../button/Button";

const CLdevisModal = ({
  isValidForm,
  onValidation,
  tarification,
  children,
  ...props
}) => {
  const { isShowing, toggle, openModal } = useModal();
  const { isShowingNotification, toggleNotification } = useNotification();

  const handleValidation = async () => {
    await onValidation();
    toggle();
    toggleNotification();
  };

  return (
    <div>
      <BasicNotification
        isShowing={isShowingNotification}
        hide={toggleNotification}
        text="devis envoyé avec succès"
      />
      <Button
        type="submit"
        libelle="Demande de devis"
        color={"color-secondary"}
        onClick={openModal}
      />

      <ValidationMailModal
        isShowing={isValidForm && isShowing}
        hide={toggle}
        color={"color-secondary"}
        onClick={() => handleValidation()}
        title="Demande de devis"
        textButtonValidation="Demander un devis"
      >
        <div className="cl-devis-dispe-modal">
          <div className="cl-devis-dispe-card">
            <p className="cl-devis-dispe-card__title">
              Un mail récapitulatif va vous être envoyé.
            </p>
            <ul className="cl-devis-dispe-card__info">
              {props.email && (
                <li>
                  <span>Mail de réception : </span>
                  {props.email}
                </li>
              )}
              {props.affichage !== false && tarification?.montant && (
                <>
                  <li>
                    <span>Nombre {tarification.service} :</span>{" "}
                    {tarification.quantite !== null ? (
                      <>
                        {tarification.quantite}
                        <span className="sub-text">
                          dernière mise à jour le {formatDate(tarification.maj)}
                        </span>
                      </>
                    ) : (
                      "Contacter votre interlocuteur Inedis"
                    )}
                  </li>
                  <li>
                    <span>Montant estimé : </span>
                    {formatPrix(tarification.montant)}
                  </li>
                </>
              )}
              {children}
            </ul>
          </div>
        </div>
      </ValidationMailModal>
    </div>
  );
};

export default CLdevisModal;
