// src/components/PLV.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import PLVSkeleton from "./skeleton/PLVSkeleton";
import { formatPrix } from "../../../../../utils/dates";

const PLV = () => {
  const {
    operationsData,
    inputPlvValues,
    handleInputChangeForId,
    errorMessages,
    isLoading,
  } = useContext(OperationContext);
  console.log(operationsData);
  console.log(inputPlvValues);
  if (isLoading) {
    return <PLVSkeleton />;
  }
  return (
    <div className="block small plv">
      <h3>PLV</h3>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            {operationsData.params?.map((param) => (
              <label
                htmlFor={param.id_operation_param}
                key={param.id_operation_param}
              >
                {param.opt_libelle} - P.U {formatPrix(param.opt_pu_ht)}
              </label>
            ))}
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            {operationsData.params?.map((param) => (
              <React.Fragment key={param.id_operation_param}>
                <input
                  id={param.id_operation_param}
                  type="number"
                  value={inputPlvValues[param.id_operation_param] || ""}
                  onChange={(e) =>
                    handleInputChangeForId(e, param.id_operation_param)
                  }
                  onWheel={(e) => e.target.blur()}
                />
                {errorMessages[param.id_operation_param] && (
                  <div className="error-message">
                    {errorMessages[param.id_operation_param]}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PLV;
