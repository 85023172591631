import React from "react";
import { DOMAIN } from "../../../../../config/env";

const Valider = ({ data, year }) => {
  return (
    <div className="valider-container">
      <img
        src={`${DOMAIN.URL_WEB}fichiers/cmu/repiquages/${data.mr_fichier}`}
        alt=""
        className="repiquage-image"
      />
      <p>Votre repiquage a bien été validé pour l'année {year}.</p>
      <p>
        Pour toute modification, veuillez passer par votre interlocuteur Inedis
        habituel
      </p>
    </div>
  );
};

export default Valider;
