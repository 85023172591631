// src/components/generalCarnet.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import PLVSkeleton from "./skeleton/PLVSkeleton";
import { formatDate } from "../../../../../utils/dates";

const GeneralCarnet = () => {
  const {
    magOpeData,
    operationsData,
    formState,
    handlePersonnalisationChoixChange,
    handleInputChange,
    isLoading,
  } = useContext(OperationContext);

  const isInputDisabled =
    magOpeData?.potentiel?.mp_qte_courrier == null &&
    magOpeData?.potentiel?.mp_qte_courrier === 0;

  const inputValue = isInputDisabled
    ? magOpeData?.potentiel?.mp_qte_courrier
    : formState.mo_courrier_qte;

  if (isLoading) {
    return <PLVSkeleton />;
  }
  return (
    <div className="block small generalCarnet">
      <h3>{operationsData.ope_libelle}</h3>
      <label>{operationsData.ope_description}</label>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="mo_courrier_qte">Nombre d'exemplaires</label>
            <label htmlFor="bonial-choix">Exemplaires de caisse</label>
            {operationsData.ope_personnalisation === "1" && (
              <label htmlFor="mo_courrier_personnalisation">
                Personnalisation
              </label>
            )}
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <input
              id="mo_courrier_qte"
              name="mo_courrier_qte"
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              disabled={isInputDisabled}
            />
            <select
              id="exemplaires-caisse"
              className="styled-select"
              name="mo_qte_caisse"
              value={formState.mo_qte_caisse}
              onChange={handleInputChange}
            >
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={200}>200</option>
            </select>
            {operationsData.ope_personnalisation === "1" && (
              <select
                id="personnalisation-choix"
                className="styled-select"
                name="mo_courrier_personnalisation"
                value={formState.mo_courrier_personnalisation}
                onChange={(e) =>
                  handlePersonnalisationChoixChange(e.target.value)
                }
              >
                <option value="0">Non</option>
                <option value="1">Oui</option>
              </select>
            )}
          </div>
        </div>
      </div>

      {formState.mo_courrier_personnalisation === "1" && (
        <div className="footer-section">
          <textarea
            id="specific-cover"
            name="mo_courrier_personnalisation_txt"
            value={formState.mo_courrier_personnalisation_txt}
            onChange={handleInputChange}
            placeholder="Entrez la description de la personnalisation..."
          />
        </div>
      )}
      <div className="footer-section">
        <label>Sélectionnez une Date</label>
        {operationsData.ope_datedeb1 && operationsData.ope_datefin1 && (
          <label>
            <input
              type="radio"
              name="mo_choix_date"
              value="1"
              checked={formState.mo_choix_date === "1"}
              onChange={handleInputChange}
            />
            Du {formatDate(operationsData.ope_datedeb1)} au{" "}
            {formatDate(operationsData.ope_datefin1)}
          </label>
        )}
        {operationsData.ope_datedeb2 && operationsData.ope_datefin2 && (
          <label>
            <input
              type="radio"
              name="mo_choix_date"
              value="2"
              checked={formState.mo_choix_date === "2"}
              onChange={handleInputChange}
            />
            Du {formatDate(operationsData.ope_datedeb2)} au{" "}
            {formatDate(operationsData.ope_datefin2)}
          </label>
        )}
        {operationsData.ope_dateautre === 1 && (
          <label>
            <input
              type="radio"
              name="mo_choix_date"
              value="3"
              checked={formState.mo_choix_date === "3"}
              onChange={handleInputChange}
            />
            Autre Date
          </label>
        )}
        {formState.mo_choix_date === "3" && (
          <div className="other-date-inputs">
            <label>
              Date de début:
              <input
                type="date"
                name="mo_datedeb3"
                value={formState.mo_datedeb3}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Date de fin:
              <input
                type="date"
                name="mo_datefin3"
                value={formState.mo_datefin3}
                onChange={handleInputChange}
              />
            </label>
          </div>
        )}
        {operationsData.ope_couv_spe === 1 && (
          <div>
            <label htmlFor="specific-cover">
              Couverture spécifique {operationsData.couv_spe.param_valeur_num} €
            </label>
            <textarea
              id="specific-cover"
              name="mo_txt_couv_spe"
              value={formState.mo_txt_couv_spe}
              onChange={handleInputChange}
              placeholder="Entrez le texte ici..."
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralCarnet;
