// src/components/SMSClientFid.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import { formatDate } from "../../../../../utils/dates";
import SMSSkeleton from "./skeleton/SMSSkeleton";

const SMSClientFid = () => {
  const {
    operationsData,
    magOpeData,
    formState,
    handleInputChange,
    isLoading,
  } = useContext(OperationContext);

  const preventNegativeInput = (e) => {
    if (e.key === "-" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  if (isLoading) {
    return <SMSSkeleton />;
  }
  const isEditable =
    !magOpeData.potentiel || magOpeData.potentiel.mp_qte_sms === "0";
  return (
    <div className="block small sms-client-fid">
      <h3>SMS - vos clients fidélisés</h3>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="type-select">Sélectionnez une option</label>
            <select
              id="type-select"
              className="styled-select"
              name="mo_sms_type"
              value={formState.mo_sms_type}
              onChange={handleInputChange}
            >
              <option value="0">Non</option>
              {operationsData.ope_sms === 1 && <option value="1">SMS</option>}
              {operationsData.ope_sms_lp === 1 && (
                <option value="2">Sms Landing Page</option>
              )}
            </select>
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <label htmlFor="qteSms">Quantité</label>
            <input
              id="qteSms"
              name="mo_sms_qte"
              type="number"
              value={formState.mo_sms_qte}
              onChange={handleInputChange}
              disabled={!isEditable}
              onKeyDown={preventNegativeInput}
            />
          </div>
        </div>
      </div>
      <div className="footer-section">
        {magOpeData.potentiel && (
          <label>
            Quantité mise à jour le{" "}
            {formatDate(magOpeData.potentiel.mp_date_maj)}
          </label>
        )}
      </div>
    </div>
  );
};

export default SMSClientFid;
