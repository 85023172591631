import React, { useState } from "react";
import { DOMAIN } from "../../../../../config/env";

const Attente = ({ data, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setErrorMessage("");
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    if (!selectedOption) {
      setErrorMessage("Veuillez sélectionner une option.");
      return;
    }
    if (selectedOption === "corrige" && comment.trim() === "") {
      setErrorMessage(
        "Le commentaire est obligatoire lorsque vous corrigez le repiquage."
      );
      return;
    }

    const updatedData = {
      ...data,
      id_statut: selectedOption === "valide" ? 1 : 2,
      mr_commentaire: comment,
    };

    onSubmit(updatedData);
  };

  return (
    <div className="attente-container">
      <img
        src={`${DOMAIN.URL_WEB}fichiers/cmu/repiquages/${data.mr_fichier}`}
        alt=""
        className="repiquage-image"
      />

      <div className="options">
        <label>
          <input
            type="radio"
            value="valide"
            checked={selectedOption === "valide"}
            onChange={handleOptionChange}
          />
          Je valide mon repiquage
        </label>
        <label>
          <input
            type="radio"
            value="corrige"
            checked={selectedOption === "corrige"}
            onChange={handleOptionChange}
          />
          Je corrige mon repiquage
        </label>
      </div>
      {selectedOption === "corrige" && (
        <div className="comment-section">
          <textarea
            placeholder="Laissez un commentaire..."
            value={comment}
            onChange={handleCommentChange}
            required
          />
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button onClick={handleSubmit} className="submit-button">
        Envoyer
      </button>
    </div>
  );
};

export default Attente;
