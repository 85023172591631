import React, { useState } from "react";
import "./style.scss";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ImportExcelModal = ({
  isOpen,
  onClose,
  onImport,
  selectedZone,
  zoneName,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [importing, setImporting] = useState(false);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setError("");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setSelectedFile(e.dataTransfer.files[0]);
    setError("");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      setError("Veuillez sélectionner un fichier.");
      return;
    }
    if (!selectedZone) {
      setError("Veuillez sélectionner une zone.");
      return;
    }
    setImporting(true);
    try {
      const response = await onImport(selectedFile);
      setSuccessMessage(response.success);
    } catch (error) {
      setError(error.message);
    }
    setImporting(false);
  };

  const handleClose = () => {
    onClose();
    setSuccessMessage("");
    setSelectedFile(null);
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <div className="confirmation-modal-header">
          <div className="confirmation-modal-title">
            <h3>Importer un fichier Excel</h3>
          </div>
          <button className="confirmation-close-button" onClick={handleClose}>
            ×
          </button>
        </div>
        <div className="confirmation-modal-body">
          <div className="info-container">
            <WarningAmberIcon className="warning-icon" />
            <div className="info-text">
              <p>
                Vous pouvez importez un fichier Excel ci dessous. Attention la
                première ligne ne sera pas importée (A1),
              </p>
              <p>
                Et l'importation du fichier fait un annule et remplace sur la
                zone sélectionnée "{zoneName}".
              </p>
            </div>
          </div>
          <div
            className="file-drop-container"
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              id="fileInput"
              type="file"
              accept=".xls,.xlsx"
              onChange={handleFileChange}
            />
            {selectedFile ? (
              <p>{selectedFile.name}</p>
            ) : (
              <p>
                Déposez votre fichier ici ou cliquez pour sélectionner un
                fichier
              </p>
            )}
          </div>
          {error && <p className="error-message">{error}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
        </div>
        <div className="confirmation-modal-footer">
          {!successMessage ? (
            <>
              <button
                type="button"
                className="confirmation-cancel-button"
                onClick={handleClose}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="confirmation-add-button"
                onClick={handleSubmit}
                disabled={importing}
              >
                Importer
              </button>
            </>
          ) : (
            <button
              type="button"
              className="confirmation-add-button"
              onClick={handleClose}
            >
              Fermer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportExcelModal;
