// src/components/SMSLocBdd.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import SMSSkeleton from "./skeleton/SMSSkeleton";

const SMSLocBdd = () => {
  const { operationsData, formState, handleInputChange, isLoading } =
    useContext(OperationContext);

  const preventNegativeInput = (e) => {
    if (e.key === "-" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  if (isLoading) {
    return <SMSSkeleton />;
  }

  return (
    <div className="block small sms-location-bdd">
      <h3>SMS avec location de base de données</h3>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="type-select">Sélectionnez une option</label>
            <select
              id="type-select"
              className="styled-select"
              name="mo_loc_sms_type"
              value={formState.mo_loc_sms_type}
              onChange={handleInputChange}
            >
              <option value="0">Non</option>
              {operationsData.ope_sms_loc_bdd === 1 && (
                <option value="1">SMS</option>
              )}
              {operationsData.ope_sms_lp_loc_bdd === 1 && (
                <option value="2">Sms Landing Page</option>
              )}
            </select>
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <label htmlFor="qteSms">Quantité</label>
            <input
              id="qteSms"
              name="mo_loc_sms_qte"
              type="number"
              value={formState.mo_loc_sms_qte}
              onChange={handleInputChange}
              onKeyDown={preventNegativeInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSLocBdd;
