import React from "react";
import { formatPrix } from "../../../../utils/dates";

const ConfirmedOperations = ({ data }) => {
  return (
    <div className="mes-engagements-container">
      <h2 className="table-title">Plan de communication validé</h2>
      <table className="engagements-table">
        <thead>
          <tr>
            <th>MOIS</th>
            <th>OPÉRATION</th>
            <th>DATES DE VALIDITE</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.mois}</td>
              <td>{row.operation}</td>
              <td>{row.dates}</td>
              <td className="total-column">{formatPrix(row.cout)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ConfirmedOperations;
