// src/components/SMSRelaisDepliant.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import { formatDate } from "../../../../../utils/dates";
import SMSSkeleton from "./skeleton/SMSSkeleton";

const SMSRelaisDepliant = () => {
  const { magOpeData, formState, handleInputChange, isLoading } =
    useContext(OperationContext);

  if (isLoading) {
    return <SMSSkeleton />;
  }
  const isEditable =
    !magOpeData.potentiel || magOpeData.potentiel.mp_qte_sms === "0";
  return (
    <div className="block small sms-relais-depliant">
      <h3>SMS Relais Opération</h3>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="type-select">Sélectionnez une option</label>
            <select
              id="type-select"
              className="styled-select"
              name="mo_sms_type"
              value={formState.mo_sms_type}
              onChange={handleInputChange}
            >
              <option value="0">Non</option>
              <option value="1">SMS</option>
              <option value="2">Sms Landing Page</option>
            </select>
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <label htmlFor="qteSms">Quantité</label>
            <input
              id="qteSms"
              name="mo_sms_qte"
              type="number"
              value={formState.mo_sms_qte}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="footer-section">
        {magOpeData.potentiel && (
          <label>
            Quantité mise à jour le{" "}
            {formatDate(magOpeData.potentiel.mp_date_maj)}
          </label>
        )}
      </div>
    </div>
  );
};

export default SMSRelaisDepliant;
