import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { PATH } from "../../../../config/constants";
import communicationNationale from "./communicationNationale.jpg";
import repiquage from "./repiquage.jpg";
import zoneDistribution from "./zoneDistribution.jpg";
import mesEngagement from "./mesEngagement.jpg";
import bonCommande from "./bonCommande.jpg";

const HomeCmNationale = () => {
  const categories = [
    {
      id: 1,
      nom: "JE M'ENGAGE SUR LA COMMUNICATION NATIONAL",
      image: communicationNationale,
      link: PATH.ENGACOM,
    },
    {
      id: 2,
      nom: "MON REPIQUAGE ADRESSE",
      image: repiquage,
      link: PATH.REPIQUAGE,
    },
    {
      id: 3,
      nom: "MA ZONE DE DISTRIBUTION",
      image: zoneDistribution,
      link: PATH.ZONE_DISTRIBUTION,
    },
    {
      id: 4,
      nom: "RECAPITULATIF DE MES ENGAGEMENTS",
      image: mesEngagement,
      link: PATH.MES_ENGAGEMENTS,
    },
    {
      id: 6,
      nom: "BONS DE COMMANDE PRODUITS",
      image: bonCommande,
      link: PATH.BDC_PROSPECTUS_PAGE,
    },
  ];

  return (
    <div>
      <h2 className="CmNationale-titre">COMMUNICATION NATIONALE</h2>
      <div className="CmNationale-container">
        {categories.map((categorie) => (
          <Link to={categorie.link} key={categorie.id}>
            <div className="CmNationale-card">
              <img src={categorie.image} alt={categorie.nom} />
              <h3>{categorie.nom}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomeCmNationale;
