// src/components/Operation.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import ReturnBtn from "../../../../../components/ui/button/ReturnBtn";
import General from "./general";
import Distribution from "./distribution";
import PLV from "./PLV";
import SMSRelaisDepliant from "./SMSRelaisDepliant";
import SMSClientFid from "./SMSClientFid";
import SMSLocBdd from "./SMSLocBdd";
import Bonial from "./bonial";
import GeneralCarnet from "./generalCarnet";
import Personnalisation from "./personnalisation";
import Budget from "./budget";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import GeneralSkeleton from "./skeleton/generalSkeleton";
import DistributionSkeleton from "./skeleton/distributionSkeleton";
import PLVSkeleton from "./skeleton/PLVSkeleton";
import SMSSkeleton from "./skeleton/SMSSkeleton";
import BonialSkeleton from "./skeleton/bonialSkeleton";
import BudgetSkeleton from "./skeleton/budgetSkeleton";
import { useNavigate } from "react-router-dom";

const Operation = () => {
  const navigate = useNavigate();
  const { operationsData, isLoading, inputPlvValues } =
    useContext(OperationContext);
  const componentsMap = {
    1: [General, Distribution, PLV, SMSRelaisDepliant, Bonial, Budget],
    2: [GeneralCarnet, SMSClientFid, SMSLocBdd, PLV, Budget],
    3: [General, SMSClientFid, SMSLocBdd, Budget],
    5: [General, SMSClientFid, SMSLocBdd, Personnalisation, Budget],
  };
  let componentsToRender = componentsMap[operationsData.id_ope_type] || [];

  if (operationsData.ope_diffusion_bonial === 0) {
    componentsToRender = componentsToRender.filter(
      (Component) => Component !== Bonial
    );
  }
  if (operationsData.ope_personnalisation === 0) {
    componentsToRender = componentsToRender.filter(
      (Component) => Component !== Personnalisation
    );
  }

  if (operationsData.ope_sms === 0 && operationsData.ope_sms_lp === 0) {
    componentsToRender = componentsToRender.filter(
      (Component) => Component !== SMSClientFid
    );
  }

  if (
    operationsData.ope_sms_loc_bdd === 0 &&
    operationsData.ope_sms_lp_loc_bdd === 0
  ) {
    componentsToRender = componentsToRender.filter(
      (Component) => Component !== SMSLocBdd
    );
  }
  if (
    typeof inputPlvValues === "object" &&
    Object.keys(inputPlvValues).length === 0
  ) {
    componentsToRender = componentsToRender.filter(
      (Component) => Component !== PLV
    );
  }
  const handleBonDeCommandeClick = (e, psp_cprospectus) => {
    e.stopPropagation();
    navigate(`/bdc/prospectus/${psp_cprospectus}`);
  };

  if (isLoading) {
    return (
      <div className="operation-container">
        <div className="CmNationale-titre">
          <Skeleton height={30} width={300} />
        </div>
        <ReturnBtn />
        <div className="operation-content">
          <GeneralSkeleton />
          <DistributionSkeleton />
          <PLVSkeleton />
          <SMSSkeleton />
          <BonialSkeleton />
          <BudgetSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="operation-container">
      <div className="CmNationale-titre">
        {operationsData.type.type_libelle}
      </div>
      <ReturnBtn />
      <div className="operation-content">
        {componentsToRender.map((Component, index) => (
          <Component key={index} />
        ))}
      </div>
      {operationsData.psp_cprospectus !== 0 && (
        <div className="bon-de-commande">
          <button
            className="bon-de-commande-btn"
            onClick={(e) =>
              handleBonDeCommandeClick(e, operationsData.psp_cprospectus)
            }
          >
            Bon de Commande Produits
          </button>
        </div>
      )}
    </div>
  );
};

export default Operation;
